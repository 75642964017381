<template>
    <div class="privacy">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    查看流程
                </div>
            </template>
        </headBack>
        <div class="hgpic_an"><img src="http://m.yihu365.cn/yhdj-news/images/img-dz.png"></div>
        <ul class="hugong_list_an services_block">
        <li>

          <dl class="intro_an">
            <h2>预约必备物品</h2>
            <dd>1、提供证明文件：正规医院开具的《处方》及《院外注射证明》等允许在院外打针输液的证明文件。</dd>
            <dd>2、药品准备：上门服务所需的生理盐水/葡萄糖注射液、药品等。</dd>
            <dd>3、物品准备：视上门服务类型及具体情况而定，数量和规格等可咨询上门服务护士。</dd>
            <dd>如上述证明文件、药品或物品准备不全，护士有权拒绝提供上门服务。</dd>
            <h2>预约流程及注意事项</h2>
            <dd>1、预约前，请仔细阅读《服务介绍》及《上门服务知情同意书》 等，并在上门服务前再次仔细确认并签署。</dd>
            <dd>2、预约发起后，请务必保持电话畅通，以便客服及护士与您联系。</dd>
            <dd>3、如护士抢约后未与您联系，请务必告知客服人员，以免延误上门时间。</dd>
            <dd>4、如抢约护士不能为您提供服务，请及时告知客服人员帮您协调其他护士上门。</dd>
            <dd>5、与护士确认上门时间、地点等相关事宜后，请务必遵守约定。如出现下列情况：</dd>
            <dd><h2 style="color:red; display:inline-block;">护士等待用户：</h2>时间≥30分钟，将收取当次服务费的30%作为违约金；时间≥1个小时，将收取当次服务费的50%作为违约金；视具体情况，护士有权不提供当次服务。</dd>
            <dd><h2 style="color:red;">退约或修改预约信息：</h2></dd>
            <dd>（1）原则上用户预约已有护士抢约，则不能取消预约。如取消，将收取预约金额30%的信息服务费。</dd>
            <dd>（2）如用户只需取消单次预约，需至少提前3小时通知上门护士。如护士已按约定出发或到达约定地点附近，则不可取消。如取消，将收取当次预约金额70%的信息服务费。</dd>
            <dd>（3）如修改预约时间、地点等，需至少提前3小时通知上门护士。如护士已按约定出发或到达约定地点附近，则不可修改。如修改，将收取当次预约金额30%的信息服务费。</dd>
            <dd>（4）所有退款均在3-15个工作日内进行原路返还，该时间包含银行退款流程。</dd>
            <dd>6、护士首次上门时请仔细查看护士证件，确认护士身份，如上门护士与抢约护士不符，请立即告知客服。如未告知客服，由此带来一切后果与医护到家平台无关。</dd>
            <dd>7、服务前，请认真阅读并签署《上门服务知情同意书》、《健康宣教及注意事项》、《用户评估表》。</dd>
            <dd>8、护士开始服务前，须“六步洗手法”认真洗手或用手部消毒液仔细消毒，并佩戴一次性口罩，防止交叉感染。</dd>
            <dd>9、服务过程中，如有任何问题，请在第一时间与客服人员联系解决。如对护士态度、服务或技术不满意等，可联系客服免费为您更换护士。</dd>
            <dd>10、服务完成后，护士须观察用户至少20分钟，确认没有问题，方可离开。</dd>
            <dd>11、服务完成后，请您及时确认已上门服务，并对护士上门服务做出客观评价、给予意见或者建议，以保证我们不断提升服务质量及用户体验。</dd>
            <br>
            <h2>如有任何问题，请第一时间联系我们</h2>
            <br>
            <dd style="display:inline-block;">客服电话：400-800-6996</dd>
            <dd style="display:inline-block;  margin-left:50px;">客服QQ ：2100703500</dd>
            <br>
            <dd style="display:inline-block;">新浪微博：医护到家</dd>
            <dd style="display:inline-block;margin-left:78px;">微信公众号：医护到家</dd>
          </dl>
        </li>
      </ul>


    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'


export default {
  name: 'privacy',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()



    return {

        router
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .content{
        margin-top:20px;
    }
    .services_block{
        padding:30px;
        padding-top:0;
        font-size: 20px;
        h3{
            font-size: 35px;
        }
    }
    .intro_an {
        // padding: 0 1rem;
        overflow: hidden;
        /* margin-right: 10px; */
    }
    .intro_an dd {
        font-size: 25px;
        line-height: 40px;
        padding:0;
        margin:0;
    }
    .hgpic_an img{
        width: 100%;
        margin-top:85px;
    }

</style>
